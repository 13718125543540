var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-10"},[_c('div',{staticClass:"card-block"},[(!_vm.loading)?_c('div',{staticClass:"misc-files simple-chirp-table"},[_c('div',{staticClass:"flex center space-between bottom-15"},[_c('h3',{staticClass:"margin-0"},[_vm._v("Uploads")]),_c('div',[_c('input',{ref:"upload",attrs:{"id":"fileid","type":"file","name":"upload","hidden":""},on:{"change":_vm.uploadFile}})]),_c('button',{staticClass:"text-button no-bg weight-600",attrs:{"type":"button"},on:{"click":_vm.selectFile}},[_c('span',{staticClass:"material-icons purple"},[_vm._v("add_box")]),_vm._v(" Upload New ")])]),_c('ChirpList',{attrs:{"column_meta":_vm.columnMeta,"data_source":_vm.rows,"totalCount":_vm.totalCount,"loadingColumns":_vm.loadingTable,"hidePaginator":true,"hideToolbar":true,"storeKey":_vm.storeKey,"useOverride":_vm.useOverride,"command-columns":_vm.commandColumns},on:{"rowSelected":_vm.getFile,"cancelOverride":function($event){_vm.useOverride = false},"buttonInCellClicked":_vm.setFileToDelete}})],1):_vm._e()]),_c('div',{staticClass:"docs-forms-table-footer"},[_c('router-link',{staticClass:"text-button",attrs:{"to":{
                   name: 'Documents',
                   params: {
                       overrideFilter: {
                           sort: {},
                           search: {},
                           filter: { 'files.client_id': [_vm.clientId], file_kind: ['external'] },
                           page: { num_per_page: 10, current_page: 1, page_num: 1 },
                           column: []
                       }
                   },
                   query: {
                       storeKey: _vm.storeKey,
                       override: true,
                       overrideFilter: _vm.qs.stringify({
                           sort: {},
                           search: {},
                           filter: { 'files.client_id': [_vm.clientId], file_kind: ['external'] },
                           page: { num_per_page: 10, current_page: 1, page_num: 1 },
                           column: []
                       })
                   }
               }}},[_vm._v(" Go to full list ")])],1),_c('MiscFilesDeleteFileModal',{attrs:{"open":_vm.deleteFileModalOpen},on:{"cancel":_vm.cancelFileDeleteModal,"handleDelete":_vm.handleDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }