<template>
    <div class="service-coordinator">
        <h3>Assigned Service Coordinator</h3>
        <div class="d-flex flex-row" v-if="primaryCounselor !== null">
            <div class="coordinator-avatar">
                <img v-if="!!primaryCounselorProfileImage" :src="primaryCounselorProfileImage" class="user-profile-img"
                     />
                <span v-else class="material-icons" style="font-size:5em">person_outline</span>
            </div>
            <div class="coordinator-label d-flex flex-column">
                <div>
                    <span class="coordinator-name">{{ primaryCounselor.firstName+" "+primaryCounselor.lastName }}</span>
                </div>
                <div class="coordinator-role">
                   <span> {{ userRole }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            No Service Coordinator asssigned.
        </div>
    </div>
</template>

<script>
import {Roles} from '@/util/globalConstants'
export default {
    name: 'AssignedServiceCoordinator',
    props: {
        primaryCounselor: {
            default: null
        },
        primaryCounselorProfileImage: {
            default: null
        }
    },
    data() {
        return {
            loading: 1
        };
    },
    computed:{
        userRole() {
                let userRole = '';
                if (!this.primaryCounselor) {
                    return '';
                }
                const userRoleId=this.primaryCounselor.role
                switch (userRoleId) {
                    case Roles.ADMIN :
                        userRole = 'Administrator';
                        break;
                    case Roles.CLINICIAN :
                        userRole = 'Clinician';
                        break;
                    case Roles.SUPPORT_STAFF :
                        userRole = 'Support Staff';
                        break;
                    case Roles.BILLER :
                        userRole = 'Biller';
                        break;
                    case Roles.CLIENT :
                        userRole = 'Client';
                        break;
                }
                return userRole;
            }
    }
}
</script>