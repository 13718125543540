var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading == false)?_c('div',{staticClass:"completed-forms"},[_c('h3',[_vm._v("All Forms")]),_c('ChirpList',{attrs:{"column_meta":_vm.columnMeta,"data_source":_vm.rows,"totalCount":_vm.totalCount,"loadingColumns":_vm.loadingTable,"hidePaginator":true,"hideToolbar":true,"storeKey":_vm.storeKey,"useOverride":_vm.useOverride},on:{"rowSelected":_vm.dynamic_target,"cancelOverride":function($event){_vm.useOverride = false}}}),_c('div',{staticClass:"top-10"},[_c('router-link',{staticClass:"text-button",attrs:{"to":{
                name: 'Forms',
                params: {
                    overrideFilter: {
                        search: {},
                        filter: {
                            client_id: [this.clientId]
                        },
                        page: {
                            num_per_page: 10,
                            page_num: 1
                        },
                        sort: {
                            date: 'Descending'
                        },
                        column: []
                    }
                }
            }}},[_vm._v(" Go to full list ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }