/* eslint-disable no-unused-vars */
<template>
    <div class="client-manager" v-if="this.$store.state.clientOverview.loaded">
        <div class="member-profile-sidebar">
            <div class="user-profile-header member-profile-header">
                <!-- <UploadOverlay/> -->
                <div class="member-profile-photo">
                    <UploadOverlay
                        :storeGetterKey="'clientOverview/getClientProfilePhoto'"
                        :storeUpdateFileKey="'clientOverview/updateField'"
                        :storeUpdateFileField="'clientProfilePhoto'"
                        :storeUpdateFileIDKey="'clientOverview/updateClientProfileId'"
                        :storeUpdateFileIDField="'client_profile_photo_file_id'"
                        :storeFileID="$store.state.clientOverview.clientData.client_profile_photo_file_id"
                         kind="client_profile_photo"
                        :client="$store.getters['clientOverview/client']"
                        :urlToUpload="
                            `/clients/client-profile-photo?fileId=${$store.state.clientOverview.clientData.client_profile_photo_file_id}`
                        "
                        v-if="$store.state.clientOverview.loaded"
                        :alt="''"
                    />
                    <!-- <img src="assets/px/client-profile-placeholder.png" alt=""/> -->
                </div>
                <div class="client-info">
                    <div class="client-full-name">
                        <b>{{ $store.getters['clientOverview/clientFullName'] }}</b>
                    </div>
                    <div class="internal-id">Internal ID# {{ $store.getters['clientOverview/client'].id }} - {{ $store.getters['clientOverview/clientStatus'] }}</div>
                    
                    <div class="client-diseases">
                        <!-- <h3>Diseases: Diabetis</h3> -->
                    </div>
                    <div class="member-profile-sidebar-buttons flex">
                        <button class="secondary top-10" type="button" @click="()=>{goToPage(sideBarTabpages[0])}">Medications</button>
                        <button class="primary top-10" type="button" @click="()=>{goToPage(sideBarTabpages[1])}">Message</button>
                    </div>
                </div>
                <div class="member-profile-sidebar-section2">
                    <!-- live vitals will go here -->
                </div>
                <!-- <div class="client-activity">
                    <div>
                        <b>{{ $store.getters['clientOverview/clientStatus'] }}</b>
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits attended:
                        {{ this.$store.state.clientOverview.attendance.attended }}
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits missed:
                        {{ this.$store.state.clientOverview.attendance.missed }}
                    </div>
                </div> -->
            </div>
            <div class="assigned-coordinator">
                <AssignedServiceCoordinator :primaryCounselor="primaryCounselor" :primaryCounselorProfileImage="primaryCounselorProfileImage"/>
            </div>
            <div class="auto-shared-forms">
                <h3>Forms Auto-Shared</h3>
                <div class="auto-shared-form-list" v-if="auto_portal_forms.length>0">    
                    <div v-for="(sharedForm, sharedFormIndex) in auto_portal_forms" :key="sharedFormIndex" class="auto-shared-form-item d-flex">
                        <div class="form-title">
                            {{ sharedForm.title }} 
                        </div>
                        <div>
                             <span class="eye-icon-custom material-icons-outlined">visibility</span>
                        </div>
                     </div>
                </div>
                <div v-else>
                        No forms auto-shared
                </div>
            </div>
            <!-- <ClientManagerTabs :pages="tabPages" @action="goToPage" /> -->
        </div>
        <div class="client-manager-tabs">
                <ClientManagerTabs :pages="tabPages" @action="goToPage" />
        </div>
        <div class="client-documents-and-forms-card flex">
            <div class="flex-1 left-col margin">
                <!-- <div class="card-block"> -->
                    <!--                    <CompletedForms :overrideFilter="{ sort: { date: 'DESC' } }" />-->
                    <CompletedForms
                        :overrideFilter="{
                            filter: {
                                'files.client_id': [this.clientId],
                                file_type: ['pdf'],
                                form_data_id: ['!0'],
                                'files.filled_by_client': ['0'],
                                'signed_by_client': ['0'],
                            }
                        }"
                    />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                    <!--                    <DraftedForms :overrideFilter="{ sort: { date: 'DESC' } }" />-->
                    <DraftedForms
                        :overrideFilter="{
                            filter: {
                                'form_data.client_id': [this.clientId],
                                'filled_by_client' : ['0', 'NULL'] // do NOT touch -alex
                                // 'form_data.sign_in_portal': ['0', 'NULL'] // do NOT touch -alex
                                // ['form_data.signature_ids']: ['NULL'] // do NOT touch -alex
                            },
                            page: {
                                num_per_page: 100,
                                page_num: 1
                            }
                        }"
                    />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                    <DraftedProgressNotes
                        :overrideFilter="{
                            filter: {
                                'notes.client_id': [this.clientId]
                                // ['form_data.signature_ids']: ['NULL'] // do NOT touch -alex
                            },
                            page: {
                                num_per_page: 100,
                                page_num: 1
                            }
                        }"
                    />
                <!-- </div> -->
                <div class="card-block">
                    <!--                    <MissingDocuments :overrideFilter="{sort: {date:'DESC'}}" />-->
                    <MissingDocuments
                        :overrideFilter="{
                            page: {
                                num_per_page: '100',
                                page_num: 1
                            },
                            filter: {
                                client_id: [this.clientId],
                                alert_type: ['missing_document']
                            }
                        }"
                    />
                </div>
            </div>
            <div class="flex-1">
                <!-- <div class="card-block"> -->
                    <NewForms />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                    <ClientToSignForms />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                    <MiscFiles />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                    <ClientedForms
                        :overrideFilter="{
                            filter: {
                               'files.client_id': [this.clientId],
                                file_type: ['pdf'],
                                form_data_id: ['!0'],
                                'signed_by_client': ['1'],
                            },

                        }"
                    />
                    <!--                    <ClientedForms />-->
                <!-- </div> -->
            </div>
        </div>
    </div>
    <Loading v-else-if="this.$store.state.clientOverview.loaded == false" />
</template>

<script>
    import ClientManagerTabs from '@/components/client-manager/ClientManagerTabs.vue';
    import UploadOverlay from '@/components/general/Upload/UploadOverlay.vue';
    import CompletedForms from '@/components/client-manager/CompletedForms';
    import MiscFiles from '@/components/client-manager/MiscFiles';
    import NewForms from '@/components/client-manager/NewForms';
    import ClientToSignForms from '@/components/client-manager/ClientToSignForms';
    import DraftedForms from '@/components/client-manager/DraftedForms';
    import AllForms from '@/components/client-manager/AllForms';
    import MissingDocuments from '@/components/client-manager/MissingDocuments';
    import Loading from '@/components/general/loading/loading.vue';
    import ClientedForms from '@/components/client-manager/ClientedForms';
    import DraftedProgressNotes from '@/components/client-manager/DraftedProgressNotes';
    import OfficeDropdown from '@/components/general/validatedInputs/OfficeDropdown';
    import {CounselorsErrorMessages, } from '@/util/globalConstants'
    import {getProfileImage} from '@/util/helpers/usersApi.helper'
    import AssignedServiceCoordinator from '@/components/client-manager/AssignedServiceCoordinator.vue'
    import {clients,users} from '@/util/apiRequests';
    export default {
        name: 'ClientDocuments',
        components: {
            ClientManagerTabs,
            UploadOverlay,
            CompletedForms,
            MiscFiles,
            NewForms,
            ClientToSignForms,
            DraftedForms,
            AllForms,
            MissingDocuments,
            Loading,
            OfficeDropdown,
            ClientedForms,
            DraftedProgressNotes,
            AssignedServiceCoordinator 
        },
        data() {
            return {
                // clientId: this.$route.params.client_id,
                clientData: null,
                tabPages: [
                    { label: 'Overview', name: 'ClientOverview', params: { record_id: this.$route.params.client_id } },
                    {
                        label: 'Demographics',
                        name: 'ClientDemographics',
                        params: { client_id: this.$route.params.client_id }
                    },

                    // {
                    //     label: 'Insurance',
                    //     name: 'ClientInsurance',
                    //     params: { client_id: this.$route.params.client_id }
                    // },

                   // { label: 'Billing', name: 'ClientBilling', params: { client_id: this.$route.params.client_id } },
                    { label: 'Clinical', name: 'ClientClinical', params: { client_id: this.$route.params.client_id } },
                    {
                        label: 'Documents & Forms',
                        name: 'ClientDocuments',
                        params: { client_id: this.$route.params.client_id }
                    },
                    {
                        label: 'Photos',
                        name: 'ClientPhotos',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Link sharing',
                        name: 'Link Sharing',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Vitals',
                        name: 'Vitals',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms" 
                    },
                ],
                sideBarTabpages:[
                {
                        label: 'Medications',
                        name: 'Medications',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms" 
                    },
                    {
                        label: 'Message',
                        name: 'Messaging',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms" 
                    },
                ],
                auto_portal_forms: [],
                primaryCounselor:null,
                primaryCounselorProfileImage:null
            };
        },
        computed: {
            clientId() {
                return this.$store.state.current_client_id;
                //return this.$store.state.clientData.
            }
        },
        methods: {
            goToPage(page) {
                this.$router.push(page);
            },
            async init() {
                if (this.$route.params.client_id && this.$route.params.client_id != 'new') {
                    this.$store.commit('persistClientId', parseInt(this.$route.params.client_id));
                    window.localStorage.setItem('last_client_id', parseInt(this.$route.params.client_id));
                    this.$store.state.current_client_id = parseInt(this.$route.params.client_id);
                }
                if (this.$route.params && this.$route.params.criteria_props) {
                    this.criteriaProps = this.$route.params.criteria_props;
                }
            },
            async getClientCounselors() {
                
            try {
                const counselors= await this.$api.get(clients.getCounselors(this.$route.params.client_id));
                const primaryCounselorId = counselors.data.counselors?.primary_counselor?.counselor_id
                if(primaryCounselorId){
                    let primaryCouselorData = await this.$api.get(users.getUserDetails(primaryCounselorId));
                    const {role,firstName,lastName,profile_photo_file_id}=primaryCouselorData.data
                    this.primaryCounselor={role,firstName,lastName,}
                    this.primaryCounselorProfileImage=await getProfileImage(profile_photo_file_id)
                }

            } catch (err) {
                this.$toasted.error(CounselorsErrorMessages.COULDNT_RETRIEVE_CLIENT_COUNSELORS);
            }
            },
            async getAutoPortalForms() {
                const res = await this.$api.get(`/form_data/get-client-forms2complete/${this.record_id}`);
                if (res.data.rows) {
                    this.auto_portal_forms = [];
                    for (const row of res.data.rows) {
                        let form = {
                            component_name: row.component_name,
                            record_id: 0,
                            title: row.menu_txt
                        }
                        this.auto_portal_forms.push(form)
                    }
                    
                }
            },
        },
       
        async created() {
            await this.init();
            await this.getAutoPortalForms()
            await this.getClientCounselors()
        }
    };
</script>