var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-10"},[_c('div',{staticClass:"card-block"},[(!_vm.loading)?_c('div',{staticClass:"simple-chirp-table"},[_c('h3',[_vm._v("Drafted Progress Notes")]),_c('ChirpList',{staticClass:"simple",attrs:{"column_meta":_vm.columnMeta,"data_source":_vm.rows,"totalCount":_vm.totalCount,"loadingColumns":_vm.loadingTable,"hidePaginator":true,"hideToolbar":true,"storeKey":_vm.storeKey,"useOverride":_vm.useOverride},on:{"rowSelected":_vm.dynamic_target,"cancelOverride":function($event){_vm.useOverride = false}}})],1):_vm._e()]),_c('div',{staticClass:"docs-forms-table-footer"},[_c('router-link',{staticClass:"text-button",attrs:{"to":{
                    name: 'Notes',
                    params: {
                        overrideFilter: {
                            sort: {},
                            search: {},
                            filter: {
                                'notes.client_id': [_vm.clientId]
                            },
                            page: { num_per_page: '10', current_page: 1, page_num: 1 },
                            column: []
                        }
                    },
                    query: {
                        storeKey: _vm.storeKey,
                        override: true,
                        overrideFilter: _vm.qs.stringify({
                            sort: {},
                            search: {},
                            filter: {
                                'notes.client_id': [_vm.clientId]
                            },
                            page: { num_per_page: '10', current_page: 1, page_num: 1 },
                            column: []
                        })
                    }
                }}},[_vm._v(" Go to full list ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }