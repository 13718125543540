var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-10"},[_c('div',{staticClass:"card-block"},[(!_vm.loading)?_c('div',{staticClass:"completed-by-practice completed-forms simple-chirp-table"},[_vm._m(0),_c('ChirpList',{attrs:{"column_meta":_vm.columnMeta,"data_source":_vm.rows,"totalCount":_vm.totalCount,"loadingColumns":_vm.loadingTable,"hidePaginator":true,"hideToolbar":true,"storeKey":_vm.storeKey,"useOverride":_vm.useOverride,"prevent-nav-on-col-label":'shared to portal'},on:{"rowSelected":_vm.getFile,"cancelOverride":function($event){_vm.useOverride = false},"specialCellClicked":_vm.handleShareToPortal}})],1):_vm._e()]),_c('div',{staticClass:"docs-forms-table-footer"},[_c('router-link',{staticClass:"text-button",attrs:{"to":{
                    name: 'Documents',
                    params: {
                        overrideFilter: {
                            sort: { date: 'DESC' },
                            search: {},
                            filter: {
                                'files.client_id': [this.clientId],
                                file_type: ['pdf'],
                                form_data_id: ['!0'],
                                'files.filled_by_client': ['0'],
                                'signed_by_client': ['0'],
                            },
                            page: { num_per_page: 10, current_page: 1, page_num: 1 },
                            column: [
                                'first_name',
                                'last_name',
                                'tags',
                                'filled_by_client',
                                'signed_by_client',
                                'page_title',
                                'downloads'
                            ]
                        }
                    },
                    query: {
                        storeKey: this.storeKey,
                        override: true,
                        overrideFilter: _vm.qs.stringify({
                            sort: { date: 'DESC' },
                            search: {},
                            filter: {
                                'files.client_id': [this.clientId],
                                file_type: ['pdf'],
                                form_data_id: ['!0'],
                                'files.filled_by_client': ['0'],
                                'signed_by_client': ['0'],
                            },
                            page: { num_per_page: 10, current_page: 1, page_num: 1 },
                            column: [
                                'first_name',
                                'last_name',
                                'tags',
                                'filled_by_client',
                                'signed_by_client',
                                'page_title',
                                'downloads'
                            ]
                        })

                    }
                }}},[_vm._v(" Go to full list ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_vm._v("Forms Completed by Practice "),_c('br')])}]

export { render, staticRenderFns }