import api from '../api';
import {file} from '../apiRequests';

export async function getProfileImage(fileId){
    try {
        let profilePhotoUrl = await getUserProfilePhotoDownloadUrl(fileId);
        if (!profilePhotoUrl) {
            return null;
        }
        let response = await api.get(profilePhotoUrl);
        return response?.data?.file?.Body ? `data:image;base64, ${response?.data?.file.Body}` : null;
    } catch (err) {
        console.error(err);
        return null;
    }     
}

export async function getUserProfilePhotoDownloadUrl(profilePhotoFileId) {
    try {
        return file.getProfilePhoto(profilePhotoFileId);
    } catch (err) {
        if (err) {
            return '';                    
        }
    }
}